<template>
  <header>
    <div class="">
      <b-overlay :show="showOver" variant="transparent" opacity="0.99" blur="5px" rounded="sm">
        <div class="header p-2 d-flex justify-content-between align-items-center">
          <button @click="goBack" class="btn btn-gradient-primary mr-2 "
            style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2)">{{ $t('key-126') }}</button>
        </div>


        <div class="container-fluid">
          <div class="row" >
            <div class="col-md-4 d-flex justify-content-center align-items-center">
              <div class="card p-3 mb-3 w-100" style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);">
                <div class="d-flex flex-column align-items-center">
                  <form class="w-100 mb-3" role="search" @submit.prevent="SearchLotShow()">
                    <div class="input-group">
                      <input type="search" class="form-control search-input" :placeholder="$t('SearchParcels')"
                        aria-label="Search" v-model="search" style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);"
                        @keydown.enter.prevent="SearchLotShow()" />
                      <div class="input-group-append">
                        <button type="button" class="btn btn-gradient-primary"
                          style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);" @click="SearchLotShow()"
                          :disabled="!search">
                          {{ $t('search') }}
                        </button>
                      </div>
                    </div>
                  </form>

                  <!-- Customer Information -->
                  <div class="text-data  w-100" >
                    <div class="row d-flex" >
                      <div class="col-md-6 ">
                        <p>{{ $t('lotSequence') }} : {{ postitem && postitem.lot_name ? postitem.lot_name + '/'
                          + postitem.lot_order : '-' }}</p>
                        <p>{{ $t('parcelCode') }} : {{ postitem && postitem.postnumber ? postitem.postnumber : '-' }}
                        </p>
                        <p>{{ $t('customerCode') }} : {{ postitem && postitem.username ? postitem.username : '-' }}</p>
                        <p>{{ $t('enterWarehouse') }} : {{ postitem && postitem.come_chinesewarehouse ?
                          postitem.come_chinesewarehouse : '-' }}</p>
                        <p>{{ $t('warehouseExit') }} : {{ postitem && postitem.out_chinesewarehouse ?
                          postitem.out_chinesewarehouse : '-' }}</p>
                      </div>
                      <div class="col-md-6 ">
                        <p>{{ $t('arrivedThailand') }} : {{ postitem && postitem.come_thaiwarehouse ?
                          postitem.come_thaiwarehouse : '-' }}</p>
                        <p>{{ $t('quantity') }} : {{ postitem && postitem.product_amount ? postitem.product_amount : '-'
                          }}</p>
                        <p>{{ $t('weight') }} : {{ postitem && postitem.weight ? postitem.weight : '-' }}</p>
                        <p>{{ $t('cubic') }} : {{ postitem && postitem.charge_cue ? postitem.charge_cue : '-' }}</p>
                        <p>{{ $t('arrange') }} : {{ postitem && postitem.collocate_wh_name ? postitem.collocate_wh_name
                          : '-' }}</p>
                      </div>
                    </div>
                  </div>

                  <div class="text-center mb-2">
                    <v-select class="select-shelve" v-model="sectionEdit"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="name" :placeholder="$t('key-14')"
                      :options="sectionlist" :reduce="text => text._id" />
                  </div>

                  <div class="d-flex w-100 justify-content-center">
                    <button type="button" class="btn btn-gradient-danger mr-1"
                      style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);" @click="resetForm()">
                      {{ $t('key') }}
                    </button>
                    <button type="button" class="btn btn-gradient-primary"
                      style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);" @click="UpdateShelve()">
                      {{ $t('key-13') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Right Column -->
            <div class="card col-md-8 " style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);">
              <!-- Recent Updates Card -->
              <div class="text-center mt-2"
                style="font-size: 1.5em; font-weight: bold; color: #1a5276;  padding-bottom: 5px; text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);">
                {{ $t('รายการที่อัพเดทล่าสุด') }}
              </div>
              <div class="card " style="box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.2);">
                <div class="pl-2 pr-2">
                  <!-- Table -->
                  <b-table striped hover responsive :items="incomeItems" :fields="incomefields" show-empty
                    :tbody-tr-class="rowClass">
                    <template #empty>
                      <div class="text-center">
                        <img src="@/assets/images/anan-img/empty/empty.png" alt="empty" width="180px" />
                        <p>No Data</p>
                      </div>
                    </template>
                  </b-table>

                  <!-- Pagination -->
                  <div class="demo-spacing-0 d-flex justify-content-end ">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number
                      last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                      @input="GetHistoryCollocate">
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--  -->
      </b-overlay>
    </div>
  </header>
</template>

<script>
import {

  BButton,
  BTable,
  BLink,
  BFormSelect,
  BModal,
  BPagination,
  BFormRadio,
  BFormGroup,
  BFormCheckbox,
  BOverlay,
} from "bootstrap-vue";
import vSelect from 'vue-select';
import moment from 'moment-timezone';
export default {
  components: {
    BButton,
    BTable,
    BLink,
    BFormSelect,
    BModal,
    BPagination,
    BFormRadio,
    BFormGroup,
    BFormCheckbox,
    BOverlay,
    vSelect,

  },

  data() {
    return {
      selectedLot: null,
      search: '',
      optionsLeft: [],
      optionsRight: [],
      lotitems: [],
      incomeItems: [],
      ID: this.$route.params.id,
      showOver: false,
      sectionlist: [],
      sectionEdit: '',
      WarehuseData: {},
      currentPage: 1,
      perPage: 10,
      totalRows: 0,
      postitem: [],
      rowClass: '',
    };
  },
  computed: {
    lotfields() {
      return [];
    },
    incomefields() {
      return [
        {
          key: 'lot_name', label: this.$t('lot'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',tdClass: 'text-center',
        },
        {
          key: 'lot_order', label: this.$t('sequence'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'postnumber', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center',tdClass: 'text-center',
        },
        {
          key: 'username', label: this.$t('customerCode'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'created_at', label: this.$t('transactionDate'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center', formatter: value => moment(value).format('DD/MM/YYYY HH:mm:ss'),
        },

        {
          key: 'collocate_wh_name', label: this.$t('arrange'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },

        {
          key: 'edit_by', label: this.$t('editedBy'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ];
    },
    filteredOptions() {
      if (this.search) {
        const searchTerm = this.search.toLowerCase()
        return this.optionsLeft.filter(option => option.postnumber.toLowerCase().includes(searchTerm))
      }
      if (this.selectedLot) {
        return this.optionsLeft.filter(option => option.postnumber.toLowerCase().includes(this.selectedLot.postnumber))
      }
      return []
    },
  },
  mounted() {
    this.GetHistoryCollocate();
    // this.ShowPost();
    this.getSection();
  },
  methods: {
    time(data) {
      return moment(data).format('DD/MM/YYYY HH:mm:ss')
    },
    goBack() {
      window.history.back();
    },
    resetForm() {
      this.sectionEdit = null;
      this.search = null;
      this.postitem = null;
    },
    SearchLotShow() {
      this.showOver = true
      if (this.search) {
        const params = {
          postnumber: this.search
        }
        this.$http.get('/warehouseTH/FindPostNumber', { params }).then(response => {
          if (response.data.data) {
            this.postitem = response.data.data
            this.showOver = false
          } else {
            this.postitem = null
            this.makeToast('warning', this.$t('key-52'), this.$t('key-274'));
            this.showOver = false
          }
        })
      }
      this.sectionEdit = null;
    },
    getSection() {
      this.$http.get('/warehouses/getlist').then(response => {
        this.sectionlist = response.data.data
      })
    },
    async ShowPost() {
      this.showOver = true;
      const obj2 = {
        ID: this.ID,
      };
      this.$http
        .post("/warehouseTH/showpost", obj2)
        .then((response) => {
          this.lotitems = response.data.data.Post_cn;
          this.optionsLeft = response.data.data.Post_cn;
          this.optionsRight = []
          this.showOver = false;
          console.log(this.lotitems)
        })
        .catch(() => {
          this.showOver = false;
        });
    },
    async GetData() {
      try {
        const params = {
          ID: this.ID,
        }
        const { data: res } = await this.$http.get('/warehouseTH/show', { params })
        this.WarehuseData = res.data
        const LotID = Object.values(this.WarehuseData.wh_lot).map(item => item.lot_id)
        this.RightLot(LotID)
        this.ShowPost(this.ID)
      } catch (error) {
        console.log(error)
      }
    },
    async UpdataRemark() {
      try {
        const obj = {
          ID: this.ID,
          remark: this.WarehuseData.remark,
        }
        await this.$http.post('/warehouseTH/updateremark', obj)
        this.makeToast('success', this.$t('key-276'), this.$t('key-277'))
      } catch (error) {
        console.log(error)
        this.makeToast('danger', this.$t('key-276'), this.$t('key-278'))
      }
    },
    makeToast(variant = null, title, mes) {
      console.debug('makeToast called with:', variant, title, mes);
      this.$bvToast.toast(mes, {
        title,
        variant,
        solid: true,
      })
    },
    Alert(status, title, mes) {
      this.$swal({
        icon: `${status ? 'success' : 'error'}`,
        title: `<h3 style="color: #141414">${title}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    UpdateCollocate() {
      this.showOver = true
      const SecJson = this.sectionlist.find(item => item._id === this.sectionEdit)
      this.CollocatePre.collocate_wh_id = SecJson ? SecJson._id : null
      this.CollocatePre.collocate_wh_name = SecJson ? SecJson.name : null
      this.$http.post('/postnumber/update', this.CollocatePre)
        .then(() => {
          this.showOver = false
          this.makeToast('success', this.$t('key-14'), this.$t('key-271'))
        })
        .catch(() => {
          this.makeToast('danger', this.$t('key-14'), this.$t('key-272'))
          this.showOver = false
        })
    },
    UpdateShelve() {
      if (!this.sectionEdit) {
        this.makeToast('danger', this.$t('key-14'), this.$t('key-272'));
        return;
      }
      if (!this.postitem || !this.postitem._id) {
        this.makeToast('danger', 'key-274');
        return;
      }
      const obj = {
        collocate_wh_id: this.sectionEdit,
        collocate_wh_name: this.sectionlist.find(section => section._id === this.sectionEdit)?.name || null,
        post_id: this.postitem._id
      };
      this.showOver = true;
      this.$http.post('/warehouseTH/UpdateShelve', obj)
        .then(() => {
          this.ShowPost(this.ID);
          this.GetHistoryCollocate();
          this.makeToast('success', this.$t('key-14'), this.$t('key-271'));
        })
        .catch(err => {
          console.error('Error in UpdateShelve:', err);
          this.showOver = false;
          this.makeToast('danger', this.$t('key-14'), this.$t('key-272'));
        });
    },

    async GetHistoryCollocate() {
      try {
        this.showOver = true
        const params = {
          perPage: this.perPage,
          page: this.currentPage,
        }
        const { data: res } = await this.$http.get('/warehouseTH/GetHistoryCollocate', { params })
        this.incomeItems = res.data
        this.totalRows = res.total
        this.showOver = false
      } catch (err) {
        console.log(err)
        this.showOver = false
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.select-shelve {
  width: 150px;
}

.text-data {
  text-align: center;
  font-size: 14px;
  // font-weight: bold;
}
</style>
